<template>
  <v-row no-gutters align="center" class="mb-3">
    <h2>{{ title }}</h2>
    <v-spacer/>
    <v-tooltip v-if="!hideRefresher" top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn @click="$emit('refresh')"
               :disabled="refresherDisabling"
               v-bind="attrs"
               v-on="on"
               icon color="primary" class="mr-3">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </template>
      <span>Refresh</span>
    </v-tooltip>

    <v-btn @click="$emit('add')" :disabled="addBtnDisabling" color="primary" depressed>
      {{ btnText }}
    </v-btn>
  </v-row>
</template>

<script>
export default {
  name: "ListHeader",
  props: {
    title: String,
    btnText: {
      type: String,
      default: 'Add'
    },
    hideRefresher: {
      type: Boolean,
      default: false
    },
    addBtnDisabling: {
      type: Boolean,
      default: false
    },
    refresherDisabling: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>
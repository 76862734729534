<template>
  <div :loading="$store.state.pageLoading">
    <v-simple-table>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">
            Name
          </th>
          <th class="d-flex justify-space-between align-center">
            Actions
            <v-btn
                    color="primary"
                    elevation="2"
                    @click="openForm(null, createUserObject)"
            >Add</v-btn>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
                v-for="user in getUsers"
                :key="user.id"
        >
          <td>{{ user.userName }}</td>
          <td>
            <template>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="openForm(user, createUserObject)"
                         v-on="on"
                         v-bind="attrs"
                         icon color="info">
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="deleteItem(user.id)"
                         v-on="on"
                         v-bind="attrs"
                         icon color="red">
                    <v-icon small>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </template>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>

    <Modal v-model="modal"
           width="600px"
           title="User form">
      <Form v-if="modal"
            :data-object="dataObject"
            @close-modal="modal = false"
            @send-user="sendUser"
      />
    </Modal>

<!--    <DeleteModal v-model="deleteModal"-->
<!--                 :delete-api="deleteApi"-->
<!--                 @deleted="getUsers"-->
<!--    />-->

  </div>
</template>

<script>
import ListHeader from "@/components/ListHeader";
import Form from "./Form";
import formModal from "@/mixins/formModal";
import deleteModal from "@/mixins/deleteModal";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "User",
  data: () => ({
    total: 0,
  }),
  components: {ListHeader, Form},
  computed: {
    ...mapGetters({getUsers: 'getUsers'})
  },
  mixins: [formModal, deleteModal],
  methods: {
    ...mapActions({
      fetchUsers: 'fetchUsers',
      creatUser: 'creatUser',
      updateUser: 'updateUser',
      deleteUser: 'deleteUser'
    }),

    createUserObject (user = null) {
      const object = {
        userName: user ? user.userName : '',
        password: ''
      };
      if (user) object.id = user.id;
      return object;
    },

    async sendUser(data) {
      if (this.dataObject.id) {
        delete data.password;
        await this.updateUser(data);
      } else {
        await this.creatUser(data);
      }

      await this.fetchUsers();
      this.modal = false;
    },

    async deleteItem(id) {
      await this.deleteUser(id);
      await this.fetchUsers();
    }
  },
  watch: {

  },
  async mounted() {
    await this.fetchUsers();
  }
}
</script>

<style scoped>

</style>
<template>
  <div>
    <v-form ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="submit">
      <v-text-field v-model="dataObject.userName"
                    label="Username"
                    clearable/>
      <v-text-field v-model="dataObject.password"
                    label="Password"
                    :type="showPassword ? 'text' : 'password'"
                    :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append="showPassword = !showPassword"
                    clearable/>

      <v-row no-gutters>
        <v-spacer/>
        <v-btn @click="closeModal" color="primary" text class="mr-3">Cancel</v-btn>
        <v-btn type="submit" color="primary" depressed>Save</v-btn>
      </v-row>
    </v-form>
  </div>
</template>

<script>
  export default {
    props: {
      dataObject: Object
    },
    name: "Form",
    data: () => ({
      showPassword: false,
      valid: true
    }),
    methods: {
      async submit () {
        const validation = this.$refs.form.validate();
        if (validation) {
          this.$emit('send-user', this.dataObject)
        }
      },
      closeModal () {
        this.$emit('close-modal');
      }
    },
  }
</script>

<style scoped>

</style>